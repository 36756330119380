import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
  TextField,
} from "@mui/material";
// Context
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
import ErrorStatusContext from "../contexts/ErrorStatusContext";
import UploadStatusContext from "../contexts/UploadStatusContext";
// Utils
import {
  amplifyDeleteProject,
  amplifyDeletePileSet,
  amplifyListPileSetsByProjectId,
  amplifyGetPilesByPileSetId,
  amplifyDeleteUser,
} from "../utils/amplifyUtils";
import { replaceNullWithNaN } from "../utils/csvUtils";
import { PileInputToPile } from "../utils/pileUtils";
import { createPileGeometry } from "../utils/objectUtils";
import UserSelect from "./UserSelect";
// Types
import {
  PileCSVType,
  PileDataType,
  PileInputType,
} from "../../types/pile.types";
// Theme
import { darkTheme } from "../darkTheme";
import ProgressContext from "../contexts/ProgressContext";

type Result = "confirm" | "cancel";
type DeleteItemType = "project" | "pileSet" | "user";

type DeleteItemDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  itemType: DeleteItemType;
  title: string;
  message: string;
  textFieldRef: React.RefObject<HTMLInputElement>;
  userRef: React.MutableRefObject<string[]>;
};

const handleClose = (
  event: React.MouseEvent<HTMLInputElement>,
  reason: "backdropClick"
) => {
  if (reason === "backdropClick") return;
};

const DELETE_ITEM_DIALOG = ({
  open,
  onClose,
  title,
  message,
  itemType,
  textFieldRef,
  userRef,
}: DeleteItemDialogProps) => (
  <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
      <DialogContentText>
        削除する
        {itemType === "project"
          ? "プロジェクト名"
          : itemType === "pileSet"
          ? "杭リスト名"
          : "ユーザー名"}
        を入力してください。
      </DialogContentText>
      <DialogContentText>
      {itemType === "user" && "※管理者(Admin)は削除できません。"}
      </DialogContentText>
      {(() => {
        switch (itemType) {
          case "project":
            return (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={"プロジェクト名"}
                type="text"
                fullWidth
                size="small"
                required
                inputRef={textFieldRef}
              />
            );
          case "pileSet":
            return (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={"杭リスト名"}
                type="text"
                fullWidth
                size="small"
                required
                inputRef={textFieldRef}
              />
            );
          case "user":
            return (
              <div
                style={{
                  margin: "16px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <UserSelect title="削除するユーザー" valueRef={userRef} disableAdmin/>
                <DialogContentText>
                  この操作は取り消しできません。確認のため「削除」と入力してください。
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  placeholder="削除"
                  type="text"
                  fullWidth
                  size="small"
                  required
                  inputRef={textFieldRef}
                />
              </div>
            );
        }
      })()}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose("cancel")} variant="outlined">
        キャンセル
      </Button>
      <Button
        onClick={() => onClose("confirm")}
        variant="contained"
        color="error"
        autoFocus
      >
        削除
      </Button>
    </DialogActions>
  </Dialog>
);

const useDeleteItemDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const title = useRef("");
  const message = useRef("");
  const itemTypeRef = useRef<DeleteItemType>("project");
  const textFieldRef = useRef<HTMLInputElement>(null);
  const userRef = useRef<string[]>([]);

  const openDeleteItemDialog = (itemType: DeleteItemType) => {
    switch (itemType) {
      case "project":
        title.current = "プロジェクトの削除";
        message.current = "プロジェクトを削除します。よろしいですか？";
        itemTypeRef.current = "project";
        break;
      case "pileSet":
        title.current = "杭リストの削除";
        message.current = "杭リストを削除します。よろしいですか？";
        itemTypeRef.current = "pileSet";
        break;
      case "user":
        title.current = "ユーザーの削除";
        message.current = "ユーザーを削除します。";
        itemTypeRef.current = "user";
        break;
    }
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const DeleteItemDialog = () => {
    // Context
    const { projectProvided, setProjectProvided } = useContext(ProjectContext);
    const { objectProvided, setObjectProvided } = useContext(ObjectContext);
    const { setErrorStatusProvided } = useContext(ErrorStatusContext);
    const { setProgressProvided } = useContext(ProgressContext);
    const { setUploadStatusProvided } = useContext(UploadStatusContext);

    const onClose = async (result: Result) => {
      switch (result) {
        case "confirm":
          switch (itemTypeRef.current) {
            case "project":
              if (
                !textFieldRef.current ||
                textFieldRef.current?.value !== projectProvided.project.name
              ) {
                setErrorStatusProvided(true);
                return;
              }
              // set progress
              setProgressProvided(true);
              // close dialog
              setOpen(false);
              if (resolve) {
                resolve(result);
              }
              // Delete Project
              const projectId = projectProvided.project.id;
              const resProject = await amplifyDeleteProject(projectId);
              if (!resProject) {
                setErrorStatusProvided(true);
                return;
              }
              setProgressProvided(false);
              window.location.reload();
              break;
            case "pileSet":
              if (
                !textFieldRef.current ||
                textFieldRef.current?.value !==
                  projectProvided.selectedPile.name
              ) {
                setErrorStatusProvided(true);
                return;
              }
              // set progress
              setProgressProvided(true);
              // close dialog
              setOpen(false);
              if (resolve) {
                resolve(result);
              }
              const selectedPileSetIndex = projectProvided.pileFiles.findIndex(
                (pileSet) => pileSet.id === projectProvided.selectedPile.id
              );
              const nextSelectedPileSetIndex = Math.max(
                selectedPileSetIndex - 1,
                0
              );
              // Delete PileSet
              const res = await amplifyDeletePileSet(
                projectProvided.project.id,
                projectProvided.selectedPile.id
              );
              if (!res) {
                setErrorStatusProvided(true);
                return;
              }
              // Update Project Context
              const pileSets = (await amplifyListPileSetsByProjectId(
                projectProvided.project.id
              )) as any;
              if (!pileSets) {
                setErrorStatusProvided(true);
                return;
              }
              const pileSet =
                pileSets.data.listPileSetsByProjectId[nextSelectedPileSetIndex];
              const pilesRes = (await amplifyGetPilesByPileSetId(
                projectProvided.project.id,
                pileSet.id
              )) as any;
              if (!pilesRes.data.getPilesByPileSetId) {
                return;
              }
              setProjectProvided({
                ...projectProvided,
                pileFiles: pileSets.data.listPileSetsByProjectId,
                isPileNewest: true,
                selectedPile: pileSet,
              });
              // Update Object Context
              const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map(
                (pile: PileCSVType) => {
                  return replaceNullWithNaN(pile) as PileInputType;
                }
              );
              const piles = PileInputToPile(pilesObjectArray);
              // create pile object
              const pilePoints = piles.map((pile) => {
                return { props: pile } as PileDataType;
              });
              const {
                pileObjectArray: pileObjectArrayWithInfo,
                pointsAddToMesh,
              } = createPileGeometry(pilePoints);
              setObjectProvided({
                type: "openPileFile",
                payload: {
                  mesh: {
                    pointsAddToMesh,
                  },
                  piles: pileObjectArrayWithInfo,
                },
              });

              setProgressProvided(false);
              break;
            case "user":
              if (
                !textFieldRef.current ||
                textFieldRef.current.value !== "削除" ||
                userRef.current.length < 1
              ) {
                setErrorStatusProvided(true);
                return;
              }
              // set progress
              setProgressProvided(true);
              userRef.current.forEach(async (userName) => {
                const res = await amplifyDeleteUser(userName);
                if (!res) {
                  setErrorStatusProvided(true);
                  return;
                }
              });
              // set progress
              setProgressProvided(false);
              setUploadStatusProvided(true);
              // close dialog
              setOpen(false);
              if (resolve) {
                resolve(result);
              }

              break;
            default:
              break;
          }
          break;

        case "cancel":
          setOpen(false);
          if (resolve) {
            resolve(result);
          }
          break;
      }
    };

    // useEffect(() => {
    //   if (projectProvided.selectedPile) {
    //     setOpen(true);
    //   }
    // }, [projectProvided.selectedPile]);
    return (
      <ThemeProvider theme={darkTheme}>
        <DELETE_ITEM_DIALOG
          open={open}
          onClose={onClose}
          itemType={itemTypeRef.current}
          title={title.current}
          message={message.current}
          textFieldRef={textFieldRef}
          userRef={userRef}
        />
      </ThemeProvider>
    );
  };

  return {
    DeleteItemDialog,
    openDeleteItemDialog,
  };
};

export default useDeleteItemDialog;
