import React, { useState } from "react";
// MUI
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
// Amplify
import { API } from "aws-amplify";
import { listUsers } from "../../graphql/queries";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DEFAULT_MAX_SELECTIONS = 10;

export default function UserSelect({
  title,
  valueRef,
  MAX_SELECTIONS,
  disableAdmin,
}: {
  title: string;
  valueRef: React.MutableRefObject<string[]>;
  MAX_SELECTIONS?: number;
  disableAdmin: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const loadUsers = async () => {
    const users = (await API.graphql({
      query: listUsers,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    setUserList([...users.data.listUsers]);
  };

  const handleChange = (event: any, newValue: any[]) => {
    if (newValue.length <= (MAX_SELECTIONS || DEFAULT_MAX_SELECTIONS)) {
      setSelectedUsers(newValue);
      valueRef.current = newValue.map((v: any) => v.Username);
    } else {
      console.log("Over");
    }
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      limitTags={4}
      id="user-select"
      options={userList}
      value={selectedUsers}
      noOptionsText={
        selectedUsers.length >= (MAX_SELECTIONS || DEFAULT_MAX_SELECTIONS)
          ? `最大${MAX_SELECTIONS || DEFAULT_MAX_SELECTIONS}件まで選択できます`
          : "見つかりません"
      }
      disableCloseOnSelect
      getOptionLabel={(option) => option.Username}
      getOptionDisabled={(option) =>
        option.Groups.includes("Admin") && disableAdmin
      }
      isOptionEqualToValue={(option, value) =>
        option.Username === value.Username
      }
      onOpen={async () => {
        setLoading(true);
        await loadUsers();
        setLoading(false);
      }}
      onChange={handleChange}
      renderOption={(props, option, { selected }) => {
        const userEmail = option.Attributes.find(
          (attr: any) => attr.Name === "email"
        ).Value;
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Typography>{option.Username}</Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="justify"
              sx={{ mx: 2 }}
            >
              {userEmail}
            </Typography>
          </li>
        );
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          placeholder={`ユーザーを選択してください。（最大${
            MAX_SELECTIONS || DEFAULT_MAX_SELECTIONS
          }件まで）`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
