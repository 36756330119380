import { createContext, useReducer, FC, ReactNode, Dispatch } from "react";
import { ObjectContextType } from "../../types/object.types";
// Utils
import {
  createMeshGeometry,
  setLut,
  getDistanceBetweenInitialMesh,
  convertMeshPointsToArray,
} from "../utils/meshUtils";
import {
  updatePiles,
  updatePileExtraLengthIndex,
  getWorkAreaList,
} from "../utils/pileUtils";
// Config
import {
  initialPileFilter,
  initialMarginOption,
  initialWorkArea,
} from "../config/PileConfig";
import { initialMeshColor, initialMeshGeometry } from "../config/MeshConfig";

// Types
import { Box3, Vector3 } from "three";
import {
  MarginOptionType,
  PileDataType,
  PileFilterType,
  WorkAreaType,
} from "../../types/pile.types";
import { MeshColorType, MeshType } from "../../types/mesh.types";

type Props = { children: ReactNode };
type ActionType =
  | {
      type: "createProject";
      payload: {
        mesh: { initialPoints: Vector3[]; pointsAddToMesh: Vector3[] };
        meshColor: Pick<MeshColorType, "colorMap" | "method">;
        piles: PileDataType[];
        pileMarginOption: MarginOptionType;
        pileFilter: PileFilterType;
        fillEmpty: boolean;
        callback: (piles: PileDataType[], meshObjectArray: MeshType[]) => void;
      };
    }
  | {
      type: "openProject";
      payload: {
        mesh: { initialPoints: Vector3[]; pointsAddToMesh: Vector3[] };
        meshColor: Pick<MeshColorType, "colorMap" | "method">;
        piles: PileDataType[];
        pileMarginOption: MarginOptionType;
        pileFilter: PileFilterType;
      };
    }
  | {
      type: "openPileFile";
      payload: {
        mesh: { pointsAddToMesh: Vector3[] };
        piles: PileDataType[];
      };
    }
  | {
      type: "updatePileSet";
      payload: {
        mesh: { pointsAddToMesh: Vector3[] };
        piles: PileDataType[];
        fillEmpty?: boolean;
        callback: (piles: PileDataType[]) => void;
      };
    }
  | {
      type: "setMesh";
      payload: {
        type: "initial" | "pile" | "update" | "reset";
        points?: Vector3[];
        mesh: { pointsAddToMesh: Vector3[]; initialPoints: Vector3[] };
        piles: PileDataType[];
      };
    }
  | {
      type: "setMeshColor";
      payload: Pick<MeshColorType, "colorMap" | "method">;
    }
  | {
      type: "setPiles";
      payload: {
        piles: PileDataType[];
      };
    }
  | { type: "setSelectedPile"; payload: { id: string | undefined } }
  | { type: "setPileMarginOption"; payload: MarginOptionType }
  | { type: "setPileFilter"; payload: PileFilterType }
  | { type: "setWorkArea"; payload: WorkAreaType[] }
  | { type: "setSelectedWorkArea"; payload: WorkAreaType }
  | { type: "setStashedPile"; payload: PileDataType | undefined }
  | { type: "resetProject" };
type ObjectContextValue = {
  objectProvided: ObjectContextType;
  setObjectProvided: Dispatch<ActionType>;
};

const ObjectContext = createContext<ObjectContextValue>({
  objectProvided: {} as ObjectContextType,
  setObjectProvided: () => {},
});

const emptyObject: ObjectContextType = {
  mesh: initialMeshGeometry,
  meshColor: initialMeshColor,
  piles: [],
  selectedPile: { id: undefined },
  pileMarginOption: initialMarginOption,
  pileFilter: initialPileFilter,
  workArea: initialWorkArea,
  selectedWorkArea: initialWorkArea[0],
  stashedPile: undefined,
};

const objectReducer = (
  initialObject: ObjectContextType,
  action: ActionType
): ObjectContextType => {
  const lut = initialObject.meshColor.lut;
  switch (action.type) {
    case "createProject": {
      const { geom, points3d } = createMeshGeometry([
        ...action.payload.mesh.pointsAddToMesh,
        ...action.payload.mesh.initialPoints,
      ]);
      const distance = getDistanceBetweenInitialMesh(
        [...action.payload.mesh.initialPoints],
        points3d
      );
      const box = new Box3().setFromPoints(points3d);
      const mesh = {
        geometry: geom,
        points: points3d,
        initialPoints: [...action.payload.mesh.initialPoints],
        distance,
        box,
      };
      const newPiles = updatePiles(
        action.payload.piles,
        mesh,
        action.payload.pileMarginOption,
        action.payload.pileFilter,
        action.payload.fillEmpty
      );
      const workAreaList = getWorkAreaList(newPiles);
      setLut(
        lut,
        action.payload.meshColor.colorMap,
        action.payload.meshColor.method,
        points3d,
        distance
      );

      if (action.payload.callback) {
        const meshObjectArray = convertMeshPointsToArray([
          ...action.payload.mesh.initialPoints,
        ]);
        action.payload.callback(newPiles, meshObjectArray);
      }
      return {
        ...initialObject,
        pileMarginOption: action.payload.pileMarginOption,
        pileFilter: action.payload.pileFilter,
        meshColor: { ...action.payload.meshColor, lut },
        selectedPile: { id: undefined },
        mesh,
        piles: newPiles,
        workArea: workAreaList,
        selectedWorkArea: workAreaList[0],
      };
    }
    case "openProject": {
      const { geom, points3d } = createMeshGeometry([
        ...action.payload.mesh.pointsAddToMesh,
        ...action.payload.mesh.initialPoints,
      ]);
      const distance = getDistanceBetweenInitialMesh(
        [...action.payload.mesh.initialPoints],
        points3d
      );
      const box = new Box3().setFromPoints(points3d);
      const mesh = {
        geometry: geom,
        points: points3d,
        initialPoints: [...action.payload.mesh.initialPoints],
        distance,
        box,
      };
      const newPiles = updatePiles(
        action.payload.piles,
        mesh,
        action.payload.pileMarginOption,
        action.payload.pileFilter,
        false
      );
      const workAreaList = getWorkAreaList(newPiles);
      setLut(
        lut,
        action.payload.meshColor.colorMap,
        action.payload.meshColor.method,
        points3d,
        distance
      );
      return {
        ...initialObject,
        pileMarginOption: action.payload.pileMarginOption,
        pileFilter: action.payload.pileFilter,
        meshColor: { ...action.payload.meshColor, lut },
        selectedPile: { id: undefined },
        mesh,
        piles: newPiles,
        workArea: workAreaList,
        selectedWorkArea: workAreaList[0],
      };
    }
    case "openPileFile": {
      const { geom, points3d } = createMeshGeometry([
        ...action.payload.mesh.pointsAddToMesh,
        ...initialObject.mesh.initialPoints,
      ]);
      const distance = getDistanceBetweenInitialMesh(
        [...initialObject.mesh.initialPoints],
        points3d
      );
      const box = new Box3().setFromPoints(points3d);
      const mesh = {
        geometry: geom,
        points: points3d,
        initialPoints: [...initialObject.mesh.initialPoints],
        distance,
        box,
      };
      const newPiles = updatePiles(
        action.payload.piles,
        mesh,
        initialObject.pileMarginOption,
        initialObject.pileFilter
      );
      const workAreaList = getWorkAreaList(newPiles);
      return {
        ...initialObject,
        selectedPile: { id: undefined },
        mesh,
        piles: newPiles,
        workArea: workAreaList,
      };
    }
    case "updatePileSet": {
      const newPoints = [
        ...initialObject.mesh.initialPoints,
        ...action.payload.mesh.pointsAddToMesh,
      ];
      const { geom, points3d } = createMeshGeometry(newPoints);
      const distance = getDistanceBetweenInitialMesh(
        [...initialObject.mesh.initialPoints],
        points3d
      );
      const box = new Box3().setFromPoints(points3d);
      const mesh = {
        geometry: geom,
        points: points3d,
        initialPoints: [...initialObject.mesh.initialPoints],
        distance,
        box,
      };
      const newPiles = updatePiles(
        action.payload.piles,
        mesh,
        initialObject.pileMarginOption,
        initialObject.pileFilter,
        action.payload.fillEmpty
      );
      if (action.payload.callback) {
        action.payload.callback(newPiles);
      }
      return {
        ...initialObject,
        selectedPile: { id: undefined },
        mesh,
        piles: newPiles,
      };
    }
    case "setMesh": {
      switch (action.payload.type) {
        case "initial": {
          if (!action.payload.points) {
            throw new Error("mesh file validation error");
          }
          const { geom, points3d } = createMeshGeometry(action.payload.points);
          const distance = getDistanceBetweenInitialMesh(
            [...action.payload.points],
            points3d
          );
          const box = new Box3().setFromPoints(points3d);
          return {
            ...initialObject,
            mesh: {
              geometry: geom,
              points: points3d,
              initialPoints: [...action.payload.points],
              distance,
              box,
            },
          };
        }
        case "pile": {
          if (!action.payload.points) {
            throw new Error("mesh file validation error");
          }
          const newPoints = [
            ...initialObject.mesh.initialPoints,
            ...action.payload.points,
          ];
          const { geom, points3d } = createMeshGeometry(newPoints);
          const distance = getDistanceBetweenInitialMesh(
            [...initialObject.mesh.initialPoints],
            points3d
          );
          const box = new Box3().setFromPoints(points3d);
          return {
            ...initialObject,
            mesh: {
              geometry: geom,
              points: points3d,
              initialPoints: [...initialObject.mesh.initialPoints],
              distance,
              box,
            },
          };
        }
        case "update": {
          const { geom, points3d } = createMeshGeometry([
            ...action.payload.mesh.pointsAddToMesh,
            ...action.payload.mesh.initialPoints,
          ]);
          const distance = getDistanceBetweenInitialMesh(
            [...action.payload.mesh.initialPoints],
            points3d
          );
          const box = new Box3().setFromPoints(points3d);
          const mesh = {
            geometry: geom,
            points: points3d,
            initialPoints: [...action.payload.mesh.initialPoints],
            distance,
            box,
          };
          const newPiles = updatePiles(
            action.payload.piles,
            mesh,
            initialObject.pileMarginOption,
            initialObject.pileFilter
          );
          return {
            ...initialObject,
            selectedPile: { id: undefined },
            mesh,
            piles: newPiles,
          };
        }
        case "reset": {
          const { geom, points3d } = createMeshGeometry(
            initialObject.mesh.initialPoints
          );
          const distance = getDistanceBetweenInitialMesh(
            [...initialObject.mesh.initialPoints],
            points3d
          );
          const box = new Box3().setFromPoints(points3d);
          return {
            ...initialObject,
            mesh: {
              geometry: geom,
              points: points3d,
              initialPoints: [...initialObject.mesh.initialPoints],
              distance,
              box,
            },
          };
        }
        default:
          return initialObject;
      }
    }
    case "setMeshColor": {
      const points = initialObject.mesh.points;
      const distance = initialObject.mesh.distance;
      setLut(
        lut,
        action.payload.colorMap,
        action.payload.method,
        points,
        distance
      );
      return { ...initialObject, meshColor: { ...action.payload, lut } };
    }
    case "setPiles": {
      const newPiles = updatePiles(
        action.payload.piles,
        initialObject.mesh,
        initialObject.pileMarginOption,
        initialObject.pileFilter
      );
      const workAreaList = getWorkAreaList(newPiles);
      return { ...initialObject, piles: newPiles, workArea: workAreaList };
    }
    case "setSelectedPile": {
      return { ...initialObject, selectedPile: action.payload };
    }
    case "setPileMarginOption": {
      const newPiles = updatePiles(
        initialObject.piles,
        initialObject.mesh,
        action.payload,
        initialObject.pileFilter
      );
      return {
        ...initialObject,
        pileMarginOption: action.payload,
        piles: newPiles,
      };
    }
    case "setPileFilter": {
      const newPiles = updatePileExtraLengthIndex(
        initialObject.piles,
        action.payload
      );
      return { ...initialObject, pileFilter: action.payload, piles: newPiles };
    }
    case "setWorkArea": {
      return { ...initialObject, workArea: action.payload };
    }
    case "setSelectedWorkArea": {
      return { ...initialObject, selectedWorkArea: action.payload };
    }
    case "setStashedPile": {
      return { ...initialObject, stashedPile: action.payload };
    }
    case "resetProject": {
      return emptyObject;
    }
    default:
      return initialObject;
  }
};

const ObjectProvider: FC<Props> = ({ children }) => {
  const [object, setObject] = useReducer(objectReducer, emptyObject);
  return (
    <ObjectContext.Provider
      value={{
        objectProvided: object,
        setObjectProvided: setObject,
      }}
    >
      {children}
    </ObjectContext.Provider>
  );
};

export default ObjectContext;
export { ObjectProvider };
