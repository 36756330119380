import { useRef, useState, useContext, useCallback } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
// Ampify
import { API } from "aws-amplify";
import { createMeshAndPileSet } from "../../graphql/mutations";
// Contexts
import UploadStatusContext from "../contexts/UploadStatusContext";
import ProgressContext from "../contexts/ProgressContext";
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Components
import NewProjectFileUpload from "./NewProjectFileUpload";
import useFillEmptyDesignDialog from "./useFillEmptyDesignDialog";
import UserSelect from "./UserSelect";
import AddUserButton from "./AddUserButton";
import useAddUserDialog from "./useAddUserDialog";
// Utilities
import {
  typeCSVforPileInput,
  PileInputToPile,
  isSoilDepthDesignEmpty,
  PileToPileInput,
} from "../utils/pileUtils";
import { createMeshPoints } from "../utils/meshUtils";
import { createPileGeometry } from "../utils/objectUtils";
import {
  fileUploadErrorAlert,
  fileValidationErrorAlert,
} from "../utils/alertUtils";
import { csvFileToObjectArray } from "../utils/csvUtils";
import { amplifyCreateProject } from "../utils/amplifyUtils";
// Types
import { MeshCSVType, MeshType } from "../../types/mesh.types";
import { PileCSVType, PileDataType, PileType } from "../../types/pile.types";

type NewProjectProps = {
  handleClick: (value: string) => void;
};

const NewProject = (props: NewProjectProps) => {
  // Contexts
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setObjectProvided } = useContext(ObjectContext);
  // Refs
  const projectRef = useRef(null);
  const postedRef = useRef(false); //Callbackの連続呼び出しを防ぐ
  // Props
  const { handleClick } = props;
  // Project Name
  const projectName = useRef<HTMLInputElement>(null);
  const handleTextFieldChange = () => {
    if (projectName.current && projectName.current.value === "") {
      setInputError({ ...inputError, projectName: true });
    } else {
      setInputError({ ...inputError, projectName: false });
    }
  };
  const usersRef = useRef<string[]>([]);
  const viewersRef = useRef<string[]>([]);
  // Validation
  const [inputError, setInputError] = useState({
    projectName: true,
    meshFile: true,
    pileFile: true,
  });
  // Dialog
  const { FillEmptyDesignDialog, openFillEmptyDesignDialog } =
    useFillEmptyDesignDialog();
  const { AddUserDialog, openAddUserDialog } = useAddUserDialog();

  // Mesh File
  const [meshFile, setMeshFile] = useState<File | null>(null);
  const handleMeshFileChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      setMeshFile(null);
      setInputError({ ...inputError, meshFile: true });
      return;
    }
    setMeshFile(e.target.files[0]);
    setInputError({ ...inputError, meshFile: false });
  };
  // Pile File
  const [pileFile, setPileFile] = useState<File | null>(null);
  const handlePileFileChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      setPileFile(null);
      setInputError({ ...inputError, pileFile: true });
      return;
    }
    setPileFile(e.target.files[0]);
    setInputError({ ...inputError, pileFile: false });
  };

  const callback = useCallback(
    async (piles: PileDataType[], meshObjectArray: MeshType[]) => {
      if (!postedRef.current) {
        return;
      }
      console.log("callback");
      postedRef.current = false;
      const pileInput = PileToPileInput(piles);
      const project = projectRef.current as any;
      // post mesh and pile data
      if (!project || !project.id) {
        console.log("project is not found");
        return;
      }
      try {
        const meshAndPileSetRes: any = await API.graphql({
          query: createMeshAndPileSet,
          variables: {
            projectId: project.id,
            meshInput: { points: meshObjectArray },
            pilesInput: pileInput,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        console.log(meshAndPileSetRes.data.CreateMesh);
        console.log(meshAndPileSetRes.data.CreatePileSet);
        const pileSet = meshAndPileSetRes.data.CreatePileSet;

        // set projectProvided
        setProjectProvided({
          ...projectProvided,
          project,
          isPileNewest: true,
          pileFiles: [pileSet],
          selectedPile: pileSet,
        });
      } catch (e) {
        console.log(e);
        fileUploadErrorAlert();
      }
    },
    [projectProvided, setProjectProvided]
  );

  // 作成ボタンを押したときの処理
  const createNewProject = async () => {
    const projName = projectName.current?.value ?? "";
    const users = usersRef.current;
    const viewers = viewersRef.current;
    if (projName !== "") {
      let fillEmpty: boolean = false;
      // convert mesh and pile csv file to object array
      const meshObjectArray: MeshCSVType[] = await (async () => {
        if (meshFile === null) {
          fileValidationErrorAlert("mesh");
          throw new Error("mesh file validation error");
        }
        try {
          return (await csvFileToObjectArray(
            meshFile,
            "mesh"
          )) as MeshCSVType[];
        } catch (e) {
          fileValidationErrorAlert("mesh");
          throw new Error("mesh file validation error");
        }
      })();
      let pileObjectArray: PileCSVType[] = await (async () => {
        if (pileFile === null) {
          fileValidationErrorAlert("pile");
          throw new Error("pile file validation error");
        }
        try {
          return (await csvFileToObjectArray(
            pileFile,
            "pile"
          )) as PileCSVType[];
        } catch (e) {
          fileValidationErrorAlert("pile");
          throw new Error("pile file validation error");
        }
      })();
      // check if 支持層深度（設計）and 根入れ余長（設計）are empty
      if (isSoilDepthDesignEmpty(pileObjectArray)) {
        const result = await openFillEmptyDesignDialog();
        switch (result) {
          case "yes":
            // fill empty design
            fillEmpty = true;
            break;
          case "no":
            fillEmpty = false;
            break;
          case "cancel":
            handleClick("close");
            return;
          default:
            return;
        }
      }
      const meshObjectArrayWithType = meshObjectArray.map(
        (mesh: MeshCSVType) => {
          return {
            x: parseFloat(mesh.x),
            y: parseFloat(mesh.y),
            z: parseFloat(mesh.z),
          };
        }
      );
      const pileObjectArrayWithType = typeCSVforPileInput(pileObjectArray);
      try {
        // create project
        const project = await amplifyCreateProject(projName, users, viewers);
        projectRef.current = project;
        console.log(project.id);
        // create mesh points
        const initialMeshPoints = createMeshPoints(meshObjectArrayWithType);
        // create pile object
        const piles = PileInputToPile(pileObjectArrayWithType);
        const pilePoints = piles.map((pile: PileType) => {
          return { props: pile } as PileDataType;
        });
        const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
          createPileGeometry(pilePoints);
        // 二重投稿を防ぐためpostedRefをtrueにする
        postedRef.current = true;
        // set marginOptionProvided
        setObjectProvided({
          type: "createProject",
          payload: {
            pileMarginOption: {
              method: project.settings.pileIndexParam,
              param1: project.settings.pileParamA,
              param2: project.settings.pileParamB,
              param3: project.settings.pileParamC,
              param4: project.settings.pileParamD,
            },
            pileFilter: {
              range: [
                project.settings.extraLengthParamA,
                project.settings.extraLengthParamB,
                project.settings.extraLengthParamC,
              ],
              visibleStatus: project.settings.extraLengthVisible,
            },
            meshColor: {
              colorMap: project.settings.meshColor,
              method: project.settings.meshIndexParam,
            },
            mesh: {
              initialPoints: initialMeshPoints,
              pointsAddToMesh: pointsAddToMesh,
            },
            piles: pileObjectArrayWithInfo,
            fillEmpty,
            callback,
          },
        });
        // close dialog
        setUploadStatusProvided(true);
        handleClick("close");
      } catch (e) {
        console.log(e);
        fileUploadErrorAlert();
      }
    }
  };
  // Add User
  const onAddUserClick = async () => {
    const result = await openAddUserDialog();
    switch (result) {
      case "yes":
        console.log("yes");
        break;
      case "no":
        console.log("no");
        break;
    }
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={1.0}>
          <Typography variant="subtitle1">プロジェクト名</Typography>
          <TextField
            id="projectName"
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
            defaultValue=""
            inputRef={projectName}
            helperText={
              inputError.projectName ? "プロジェクト名を入力してください" : ""
            }
            required
            error={inputError.projectName}
            onChange={handleTextFieldChange}
          />
          <Typography variant="subtitle1">地盤調査結果</Typography>
          <NewProjectFileUpload
            file={meshFile}
            handleChange={handleMeshFileChange}
          />
          <Typography variant="subtitle1">杭リスト</Typography>
          <NewProjectFileUpload
            file={pileFile}
            handleChange={handlePileFileChange}
          />
          <Typography variant="subtitle1">編集可能ユーザー</Typography>
          <UserSelect
            title="編集可能ユーザー"
            valueRef={usersRef}
            disableAdmin={false}
          />
          <Typography variant="subtitle1">閲覧ユーザー</Typography>
          <UserSelect
            title="閲覧ユーザー"
            valueRef={viewersRef}
            disableAdmin={false}
          />
          <AddUserButton onAddUserClick={onAddUserClick} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleClick("option");
          }}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          disabled={Object.values(inputError).some((item) => item)}
          onClick={async () => {
            setProgressProvided(true);
            try {
              await createNewProject();
            } catch (e) {
              console.log(e);
            }
            setProgressProvided(false);
          }}
        >
          作成
        </Button>
      </DialogActions>
      <FillEmptyDesignDialog
        title="確認"
        message="支持層深度（設計）・根入れ余長（設計）の列が空白です。ANAGO Mobileで計算した値を自動入力しますか？"
      />
      <AddUserDialog />
    </>
  );
};

export default NewProject;
