/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject($projectInput: ProjectInput!) {
    createProject(projectInput: $projectInput) {
      id
      name
      settings {
        id
        createdAt
        updatedAt
        meshColor
        meshIndexParam
        pileIndexParam
        pileParamA
        pileParamB
        pileParamC
        pileParamD
        extraLengthParamA
        extraLengthParamB
        extraLengthParamC
        extraLengthVisible
      }
    }
  }
`;
export const updateProjectSettings = /* GraphQL */ `
  mutation UpdateProjectSettings(
    $projectId: ID!
    $projectSettingsInput: ProjectSettingsInput!
  ) {
    updateProjectSettings(
      projectId: $projectId
      projectSettingsInput: $projectSettingsInput
    )
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`;
export const createMesh = /* GraphQL */ `
  mutation CreateMesh($projectId: ID!, $meshInput: MeshInput!) {
    createMesh(projectId: $projectId, meshInput: $meshInput) {
      id
      createdAt
      updatedAt
      points {
        x
        y
        z
        __typename
      }
      __typename
    }
  }
`;
export const updateMesh = /* GraphQL */ `
  mutation UpdateMesh($projectId: ID!, $meshInput: MeshInput!) {
    updateMesh(projectId: $projectId, meshInput: $meshInput)
  }
`;
export const createPileSet = /* GraphQL */ `
  mutation CreatePileSet($projectId: ID!, $pilesInput: [PileInput!]!) {
    createPileSet(projectId: $projectId, pilesInput: $pilesInput) {
      id
      name
      project
      createdAt
      updatedAt
      piles {
        id
        createdAt
        updatedAt
        index
        symbol
        diameter
        work_area
        order
        x
        y
        top_level_design
        pile_length_design
        min_embedment_design
        soil_depth_design
        extra_embedment_design
        has_middle_plan
        top_level_plan
        pile_length_plan
        min_embedment_plan
        soil_depth_plan
        extra_embedment_plan
        top_level_actual
        pile_length_actual
        soil_depth_actual
        extra_embedment_actual
        date_of_work
        description
        __typename
      }
      __typename
    }
  }
`;
export const createPile = /* GraphQL */ `
  mutation CreatePile($projectId: ID!, $pileSetId: ID, $pileInput: PileInput!) {
    createPile(
      projectId: $projectId
      pileSetId: $pileSetId
      pileInput: $pileInput
    ) {
      id
      createdAt
      updatedAt
      index
      symbol
      diameter
      work_area
      order
      x
      y
      top_level_design
      pile_length_design
      min_embedment_design
      soil_depth_design
      extra_embedment_design
      has_middle_plan
      top_level_plan
      pile_length_plan
      min_embedment_plan
      soil_depth_plan
      extra_embedment_plan
      top_level_actual
      pile_length_actual
      soil_depth_actual
      extra_embedment_actual
      date_of_work
      description
      __typename
    }
  }
`;

export const deletePileSet = /* GraphQL */ `
  mutation DeletePileSet($projectId: ID!, $pileSetId: ID!) {
    deletePileSet(projectId: $projectId, pileSetId: $pileSetId)
  }
`;

export const createMeshAndPileSet = /* GraphQL */ `
  mutation CreateMeshAndPiles(
    $projectId: ID!
    $meshInput: MeshInput!
    $pilesInput: [PileInput!]!
  ) {
    CreateMesh: createMesh(projectId: $projectId, meshInput: $meshInput) {
      id
      createdAt
      updatedAt
      points {
        x
        y
        z
        __typename
      }
      __typename
    }
    CreatePileSet: createPileSet(
      projectId: $projectId
      pilesInput: $pilesInput
    ) {
      id
      name
      project
      createdAt
      updatedAt
      piles {
        id
        createdAt
        updatedAt
        index
        symbol
        diameter
        work_area
        order
        x
        y
        top_level_design
        pile_length_design
        min_embedment_design
        soil_depth_design
        extra_embedment_design
        has_middle_plan
        top_level_plan
        pile_length_plan
        min_embedment_plan
        soil_depth_plan
        extra_embedment_plan
        top_level_actual
        pile_length_actual
        soil_depth_actual
        extra_embedment_actual
        date_of_work
        description
        __typename
      }
      __typename
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $username: String!
    $password: String!
    $email: String!
    ) {
    createUser(
      username: $username
      password: $password
      email: $email
    ) {
      Attributes{
      Name
      Value
      }
      Enabled
      UserStatus
      Username
      Groups
    }
  }
`;

export const deleteUser = /* GraphQL */ `
mutation DeleteUser($username: String!) {
  deleteUser(username: $username)
}
`;