/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProjectById = /* GraphQL */ `
  query GetProjectById($projectId: ID!) {
    getProjectById(projectId: $projectId) {
      id
      name
      createdBy
      createdAt
      updatedAt
      settings {
        id
        createdAt
        updatedAt
        meshColor
        meshIndexParam
        pileIndexParam
        pileParamA
        pileParamB
        pileParamC
        pileParamD
        extraLengthParamA
        extraLengthParamB
        extraLengthParamC
        extraLengthVisible
        __typename
      }
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects {
    listProjects {
      id
      name
      updatedAt
      __typename
    }
  }
`;
export const getMeshByProjectId = /* GraphQL */ `
  query GetMeshByProjectId($projectId: ID!) {
    getMeshByProjectId(projectId: $projectId) {
      id
      createdAt
      updatedAt
      points {
        x
        y
        z
        __typename
      }
      __typename
    }
  }
`;
export const listPileSetsByProjectId = /* GraphQL */ `
  query ListPileSetsByProjectId($projectId: ID!) {
    listPileSetsByProjectId(projectId: $projectId) {
      id
      name
      project
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getPilesByPileSetId = /* GraphQL */ `
  query GetPilesByPileSetId($projectId: ID!, $pileSetId: ID!) {
    getPilesByPileSetId(projectId: $projectId, pileSetId: $pileSetId) {
      id
      createdAt
      updatedAt
      index
      symbol
      diameter
      work_area
      order
      x
      y
      top_level_design
      pile_length_design
      min_embedment_design
      soil_depth_design
      extra_embedment_design
      has_middle_plan
      top_level_plan
      pile_length_plan
      min_embedment_plan
      soil_depth_plan
      extra_embedment_plan
      top_level_actual
      pile_length_actual
      soil_depth_actual
      extra_embedment_actual
      date_of_work
      description
      __typename
    }
  }
`;
export const getTutorial = /* GraphQL */ `
  query GetTutorial {
    getTutorial
  }
`;
export const getPileSetDifferences = /* GraphQL */ `
  query GetPileSetDifferences($projectId: ID!, $pileSetIdA: ID!, $pileSetIdB: ID!) {
    getPileSetDifferences(projectId: $projectId, pileSetIdA: $pileSetIdA, pileSetIdB: $pileSetIdB) {
    pileSetIdA
    pileSetIdB
    differences{
      pileIndex
      differentFields
    }
  } 
}
`;

export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      UserStatus
      Username
      Attributes {
        Name
        Value
      }
      Groups
    }
  }
`;